<template>
  <div class="vs-row">
    <template lang="html">
      <div>
        <vx-card actionable class="cardx" title="Exed Dues Summary">
          <vs-row type="flex" style="margin-top: 2%; margin-bottom: 2%">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4">
              <v-select
                label="Cources"
                :options="cources"
                v-model="selected_course"
                style="width: 240px; z-index: 1000"
              ></v-select>
            </vs-col>
          </vs-row>

          <vs-table multiple v-model="selected" max-items="status" :data="displayData">
            <template slot="thead">
              <vs-th> STATUS </vs-th>
              <vs-th> YEAR </vs-th>
              <vs-th>
                {{ selected_course }}
              </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td v-if="tr.year === 'Total'">
                  <b> {{ tr.status }} </b>
                </vs-td>

                <vs-td v-else>
                  {{ tr.status }}
                </vs-td>

                <vs-td v-if="tr.year === 'Total'">
                  <b>{{ tr.year }}</b>
                </vs-td>

                <vs-td v-else>
                  {{ tr.year }}
                </vs-td>

                <vs-td>
                  <a
                    @click="
                      displayIndividualData(
                        tr.exedLeads,
                        tr.status,
                        `${selected_course} - ${tr.status} - ${tr.year}`
                      )
                    "
                    >{{ tr.exed }} - ({{ tr.exedLeads.length }})</a
                  >
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
      </div>
    </template>
    <DuesSummaryPopup></DuesSummaryPopup>
  </div>
</template>
<script>
import vSelect from "vue-select";
import axios from "axios";
import constants from "../../constants.json";
import eventbus from "../components/eventbus";
import DuesSummaryPopup from "../components/pagesComponents/DuesSummaryPopup.vue";
import moment from "moment";
export default {
  data() {
    return {
      retailsEnabled: true,
      corporateEnabled: true,
      selected: "",
      cities: [],
      cources: [
        // "RPO",
        "IIML-BA",
        "IIML-FA",
        "IIML-HR",
        "IIML-PM",
        "IIML-SF",
        "IIML-FT",
        "IITR-BF",
        "IITR-DB",
        "IITM-AA",
        "IIMK-CX",
        "IITM-FS",
        "IITR-CC",
        "IIMK-FT",
        "IIML-AB",
        "IIML-SH",
        "IITJ-DE",
        "XLRI-HR",
        "XLRI-SH",
        "IIMI-BA",
        "IIMI-AA",
        "XLRI-DM"
      ],
      selected_city: "",
      selected_course: "IIML-BA",
      displayData: [],
      loggedin: "",
      all_spocs: [],
      all: [],
    };
  },
  components: {
    "v-select": vSelect,
    DuesSummaryPopup,
  },
  mounted() {
    eventbus.$on("reload-dues-data", () => {
      this.populateData();
    });
    this.cities = [];
    this.getSpocs();
  },
  watch: {
    selected_city: function () {
      this.populateData();
    },
    selected_course: function () {
      this.populateData();
    },
  },
  methods: {
    getRole(providedRole) {
      if (localStorage.getItem("role") === providedRole) {
        return true;
      } else {
        return false;
      }
    },
    getParamsObj(purchases) {
      let person_ids = [];
      purchases.forEach((purchase) => {
        person_ids.push(purchase.person_id);
      });
      let obj = {
        person_ids: person_ids.join(),
      };
      return obj;
    },
    displayIndividualData(data, status, title) {
      console.log(data, status, title, "due summary total passing pop up");
      eventbus.$emit("display-data-popup", [data, status, title]);
      // this.$vs.loading();
      // let obj = this.getParamsObj(data);
      // let url = `${constants.ADMIN_SERVER_API}getSpocNameForDues`;
      // axios
      //   .get(url, {
      //     params: obj,
      //     headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
      //   })
      //   .then((response) => {
      //     response.data.forEach((obj) => {
      //       data.forEach((purchase) => {
      //         if (parseInt(purchase.person_id) === parseInt(obj.person_id)) {
      //           purchase.spoc_name = obj.spoc_name;
      //         }
      //       });
      //     });
      //     console.log(data);
      //     this.$vs.loading.close();
      //     eventbus.$emit("display-data-popup", [data, status, title]);
      //   })
      //   .catch((error) => {
      //     this.$vs.loading.close();
      //     this.handleError(error);
      //   });
      //
    },
    getSpocs() {
      let url = `${constants.ADMIN_SERVER_API}getSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.loggedin = response.data.logged_in_user_id;
          this.all_spocs = response.data.spocs;
          this.extractSpocByCity(response.data.spocs);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    extractSpocByCity(response) {
      var unique = response
        .map((name) => {
          return {
            count: 1,
            name: name.city,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      this.cities = [];
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          this.cities.push(key);
        }
      }
      this.cities = this.sortArrayAlphabetically(this.cities);
      if (
        localStorage.getItem("role") === "cm4" ||
        localStorage.getItem("role") === "ac1"
      ) {
        this.selected_city = this.cities[0];
      } else if (localStorage.getItem("role") === "cm1") {
        this.all_spocs.forEach((spoc) => {
          if (spoc.id === this.loggedin) {
            this.selected_city = spoc.city;
          }
        });
      }
      //   this.selected_city = this.cities[0];
    },
    populateData() {
      this.$vs.loading();
      let params = {
        course: this.selected_course,
      };
      axios
        .get(`${constants.ADMIN_SERVER_API}getExEdOverDueData`, {
          params: params,
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          if (response.data.status !== "success") {
            this.handleNotification(response);
          }
          this.generateB2BAndB2CTables(response.data.purchases);
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.handleError(error);
        });
    },
    generateB2BAndB2CTables(purchases) {
      // let corporatePurchases = [];
      let nonCorporatePurchases = [];

      purchases.forEach((purchase) => {
        // if (purchase.batch.includes("EE") && purchase.b2b_self_sponsor !== 1) {
        //   corporatePurchases.push(purchase);
        // } else {
          console.log("purchase", purchase)
          nonCorporatePurchases.push(purchase);
        // }
      });

      this.displayData = this.calculateData(nonCorporatePurchases);
    },
    calculateData(purchases) {
      let displayData = [];

      let totalYears = this.getIndividualYearsFromPurchases(purchases);

      totalYears.forEach((year) => {
        var currentYear = moment().year();

        if (year === currentYear.toString()) {
          let overDueObj = this.getCurrentYearOverdueObj(purchases);
          let dueObj = this.getCurentYearDueObj(purchases);

          displayData.push(overDueObj);
          displayData.push(dueObj);
          return;
        }

        let obj = {
          status: "Overdue",
          year: year,
          exed: this.getOverdueAmount(year, this.selected_course, purchases),
          additional_purchases: this.getOverdueAmount(year, "New Purchase", purchases),
          exedLeads: this.getOverdueLeads(year, this.selected_course, purchases),
          additional_purchases_leads: this.getOverdueLeads(
            year,
            "New Purchase",
            purchases
          ),
        };

        if (obj.exed !== 0) {
          displayData.push(obj);
        }
      });

      let totalDataObj = this.getTotalDataObj(displayData);

      displayData.push(totalDataObj);

      let defaultersObj = this.getDefaultersObject(purchases);

      displayData.push(defaultersObj);

      let negativeFiguresObj = this.getnegativeFeaguresObj(purchases);

      displayData.push(negativeFiguresObj);

      let amountInProcessObj = this.getAmountInProcessObj(purchases);

      displayData.push(amountInProcessObj);

      let loanObj = this.getLoanObj(purchases);

      displayData.push(loanObj);

      return displayData;
    },
    getnegativeFeaguresObj(purchases) {
      let obj = {
        status: "Incorrect Fees",
        year: "Incorrect Fees",
        exed: 0,
        additional_purchases: 0,
        exedLeads: [],
        additional_purchases_leads: [],
      };
      purchases.forEach((purchase) => {
        if (
          Math.sign(purchase.amount_paid + purchase.amount_in_process) === -1 &&
          purchase.defaulter !== 1 &&
          purchase.loan === 0
        ) {
          if (purchase.name == this.selected_course) {
            obj.exed = obj.exed + (purchase.amount_paid + purchase.amount_in_process);
            obj.exedLeads.push(purchase);
          }
        }
      });

      return obj;
    },

    getAmountInProcessObj(purchases) {
      let obj = {
        status: "Amount in process",
        year: "Amount in process",
        exed: 0,
        additional_purchases: 0,
        exedLeads: [],
        additional_purchases_leads: [],
      };
      purchases.forEach((purchase) => {
        if (purchase.amount_in_process !== 0) {
          // console.log(purchase);
          if (purchase.name == this.selected_course) {
            obj.exed = obj.exed + purchase.amount_in_process;
            obj.exedLeads.push(purchase);
          }
        }
      });
      return obj;
    },
    getLoanObj(purchases) {
      let obj = {
        status: "Loan",
        year: "Loan",
        exed: 0,
        additional_purchases: 0,
        exedLeads: [],
        additional_purchases_leads: [],
      };
      purchases.forEach((purchase) => {
        if (
          // purchase.loan === 1 &&
          // purchase.defaulter === 1 &&
          // Math.sign(purchase.amount_pending) === 1
          purchase.defaulter === 0 &&
          purchase.loan === 1 &&
          parseInt(purchase.amount_pending) - parseInt(purchase.amount_in_process) !== 0
        ) {
          if (purchase.name == this.selected_course) {
            // obj.exed = obj.exed + purchase.amount_pending;
            obj.exed = obj.exed + (purchase.amount_paid + purchase.amount_in_process);
            obj.exedLeads.push(purchase);
          }
        }
      });
      return obj;
    },
    getTotalDataObj(displayData) {
      console.log("test", displayData)
      let obj = {
        status: "Total",
        year: "Total",
        exed: 0,
        additional_purchases: 0,
        exedLeads: [],
        additional_purchases_leads: [],
      };

      displayData.forEach((data) => {
        obj.exed = obj.exed + data.exed;
        obj.additional_purchases = obj.additional_purchases + data.additional_purchases;

        obj.exedLeads = obj.exedLeads.concat(data.exedLeads);
        obj.additional_purchases_leads = obj.additional_purchases_leads.concat(
          data.additional_purchases_leads
        );
      });

      return obj;
    },
    getDefaultersObject(purchases) {
      let obj = {
        status: "Defaulter",
        year: "Defaulter",
        exed: 0,
        additional_purchases: 0,
        exedLeads: [],
        additional_purchases_leads: [],
      };

      purchases.forEach((lead) => {
        if (
          Math.sign(lead.amount_pending) === 1 &&
          lead.defaulter === 1
        ) {
          if (lead.name == this.selected_course) {
            obj.exed = obj.exed + lead.amount_pending;
            obj.exedLeads.push(lead);
          } else if (lead.name == "New Purchase") {
            obj.additional_purchases = obj.additional_purchases + lead.amount_pending;
            obj.additional_purchases_leads.push(lead);
          }
        }
      });

      return obj;
    },
    getCurrentYearOverdueObj(purchases) {
      var currentYear = moment().year();
      // var currentMonth = moment().month();
      let leads = [];

      purchases.forEach((purchase) => {
        if (
          moment(purchase.created_at, "YYYY-MM-DD hh:mm:ss").year() === currentYear &&
          purchase.defaulter === 0 &&
          purchase.loan === 0
        ) {
          let formatted = moment(purchase.created_at, "YYYY-MM-DD hh:mm:ss").format(
            "YYYY-MM-DD"
          );

          let todayFormat = moment();
          if (todayFormat.diff(formatted, "days") > 30) {
            leads.push(purchase);
          }
        }
      });

      let obj = {
        status: "Overdue",
        year: currentYear,
        exed: 0,
        exedLeads: [],
        additional_purchases: 0,
        additional_purchases_leads: [],
      };

      leads.forEach((lead) => {
        if (Math.sign(lead.amount_pending) === 1) {
        // if (Math.sign(lead.amount_paid + lead.amount_in_process) === 1) {
          if (lead.name == this.selected_course) {
            // obj.exed = obj.exed + lead.amount_pending;
            if(lead.agreed_cost > (lead.amount_paid + lead.amount_in_process))
            {
              obj.exed = obj.exed + (lead.amount_paid + lead.amount_in_process);
              obj.exedLeads.push(lead);
            }
          } else if (lead.name == "New Purchase") {
            // obj.additional_purchases = obj.additional_purchases + lead.amount_pending;
            if(lead.agreed_cost > (lead.amount_paid + lead.amount_in_process))
            {
              obj.additional_purchases = obj.additional_purchases + (lead.amount_paid + lead.amount_in_process);
              obj.additional_purchases_leads.push(lead);
            }
          }
        }
      });

      return obj;
    },
    getCurentYearDueObj(purchases) {
      var currentYear = moment().year();
      // var currentMonth = moment().month();
      let leads = [];

      purchases.forEach((purchase) => {
        if (
          moment(purchase.created_at, "YYYY-MM-DD hh:mm:ss").year() === currentYear &&
          purchase.defaulter === 0 &&
          purchase.loan === 0
        ) {
          let formatted = moment(purchase.created_at, "YYYY-MM-DD hh:mm:ss").format(
            "YYYY-MM-DD"
          );

          let todayFormat = moment();
          if (todayFormat.diff(formatted, "days") <= 30) {
            leads.push(purchase);
          }
        }
      });

      let obj = {
        status: "Due",
        year: currentYear,
        exed: 0,
        exedLeads: [],
        additional_purchases: 0,
        additional_purchases_leads: [],
      };

      leads.forEach((lead) => {
        // if (Math.sign(lead.amount_pending) === 1) {
        if (Math.sign(lead.amount_paid + lead.amount_in_process) === 1) {
          if (lead.name == this.selected_course) {
            if(lead.agreed_cost > (lead.amount_paid + lead.amount_in_process))
            {
              // obj.exed = obj.exed + lead.amount_pending;
              obj.exed = obj.exed + lead.amount_paid + lead.amount_in_process;
              obj.exedLeads.push(lead);
            }
          } else if (lead.name == "New Purchase") {
            if(lead.agreed_cost > (lead.amount_paid + lead.amount_in_process))
            {
              obj.additional_purchases = obj.additional_purchases + (lead.amount_paid + lead.amount_in_process);
              obj.additional_purchases_leads.push(lead);
            }
            // obj.additional_purchases = obj.additional_purchases + lead.amount_pending;
          }
        }
      });

      return obj;
    },
    getOverdueAmount(year, course, purchases) {
      let leads = [];
      purchases.forEach((purchase) => {
        if (
          purchase.created_at_year.toString() === year &&
          purchase.name === course &&
          purchase.defaulter === 0 &&
          purchase.loan === 0
        ) {
          leads.push(purchase);
        }
      });

      let finalNumber = 0;

      leads.forEach((lead) => {
        if (lead.agreed_cost > (lead.amount_paid + lead.amount_in_process)) {
          if (Math.sign(lead.amount_paid + lead.amount_in_process) === 1) {
            // finalNumber = finalNumber + lead.amount_pending;
            finalNumber = finalNumber + (lead.amount_paid + lead.amount_in_process);
          }
        }
      });
      return finalNumber;
    },
    getOverdueLeads(year, course, purchases) {
      let leads = [];
      purchases.forEach((purchase) => {
        if (
          purchase.created_at_year.toString() === year &&
          purchase.name === course &&
          purchase.defaulter === 0 &&
          purchase.loan === 0
        ) {
          leads.push(purchase);
        }
      });

      let finalList = [];
      leads.forEach((lead) => {
        // if (Math.sign(lead.amount_pending) === 1) {
          if(lead.agreed_cost > (lead.amount_paid +  lead.amount_in_process))
          {
            if (Math.sign(lead.amount_paid + lead.amount_in_process) === 1) {
              finalList.push(lead);
              //   finalNumber = finalNumber + lead.amount_pending;
            }
          }
      });
      return finalList;
    },
    getIndividualYearsFromPurchases(purchases) {
      purchases.forEach((purchase) => {
        purchase.created_at_year = moment(
          purchase.created_at,
          "YYYY-MM-DD hh:mm:ss"
        ).year();
      });
      var unique = purchases
        .map((name) => {
          return {
            count: 1,
            name: name.created_at_year,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      let years = [];
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          years.push(key);
        }
      }
      console.log(years);
      return years;
    },
  },
};
</script>
<style></style>
